import { actionTypes, Common, messages } from '../../../utils';
import { axiosBasic } from '../../../api/axios';
import { config } from '../../../api/endPoints';

const { APPLICANT_ACTIONS } = actionTypes;
const { SET_UNIVERSITIES, SET_APPLICANTS } = APPLICANT_ACTIONS;
const { University, Applicant } = config.REST_API;
const {
  Errors: { requestFailed }
} = messages;

export const setUniversities = (value) => ({
  type: SET_UNIVERSITIES,
  payload: value
});

export const setApplicants = (value) => ({
  type: SET_APPLICANTS,
  payload: value
});

export const onGetUniverities = () => (dispatch) =>
  axiosBasic
    .get(University.University)
    .then((response) => {
      const { success, data, message } = response.data;

      if (success) {
        dispatch(setUniversities(data));
      } else {
        Common.showErrorMessage(message);
      }
    })
    .catch((error) => {
      console.log(error);
    });

export const onUploadApplicants = (uniId, selectedFile) => async () => {
  let formData = new FormData();
  formData.append('universityId', uniId);
  formData.append('file', selectedFile);

  const reqObj = {
    url: Applicant.AddApplicant,
    method: 'post',
    data: formData,
    headers: { 'Content-Type': 'multipart/form-data' }
  };

  try {
    const resp = await axiosBasic(reqObj);
    const { success, message } = resp.data;
    success
      ? Common.showSuccessMessage('Applicants added successfully!')
      : Common.showErrorMessage(message);

    return { success: success };
  } catch (error) {
    console.log(error);
    return { success: false };
  }
};

export const onGetAppicationCount = (universityId) => async () => {
  try {
    const response = await axiosBasic.get(`${Applicant.GetCount}/${universityId}`);
    const { success, message } = response.data;

    if (!success) {
      Common.showErrorMessage(message);
    }
    return { data: response.data };
  } catch (error) {
    console.log(error);
    Common.showErrorMessage('Unable to fetch applicants count');
  }
};

export const getApplicants = (universityId) => async (dispatch) => {
  try {
    const url = `${Applicant.Applicant}?UniversityId=${universityId}`;
    const resp = await axiosBasic.get(url);
    const { success, data } = resp.data;

    if (success) {
      await dispatch(setApplicants(data));
    } else {
      Common.showErrorMessage(requestFailed);
    }
  } catch (err) {
    console.error(err);
  }
};

export const updateApplicant = (applicant) => async (dispatch) => {
  try {
    const url = `${Applicant.Applicant}/${applicant.Id}`;
    const resp = await axiosBasic.put(url, applicant);
    const { success } = resp.data;

    if (success) {
      Common.showSuccessMessage('Applicant updated successfully!');
      await dispatch(getApplicants(applicant.UniversityId));
    } else {
      Common.showErrorMessage(requestFailed);
    }
  } catch (err) {
    console.error(err);
  }
};
