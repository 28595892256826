const HOST_URL = 'https://api.umii.app/';
// const HOST_URL = 'https://api.umii.app/';
const VERSION = 'v1.0';
export const config = {
  ROOT_URL: `${HOST_URL}api/${VERSION}/`,
  REST_API: {
    Account: {
      LogIn: 'Account/Admin/Login',
      LogOff: 'Account/Logout?DeviceIdentifier=',
      RefreshToken: 'Account/Admin/RefreshToken',
      ConfirmUser: 'Account/ConfirmUser?userId=',
      ConfirmEmail: 'Account/ConfirmEmail'
    },
    Dashboard: {
      GetUniversityDetail: 'Profile/GetUniversityDetail',
      GetUniversityStudents: 'Profile/GetUniversityStudents?UniversityName='
    },
    Domains: {
      ControllerName: 'UniversityDomains',
      GetUniversityDomains: 'UniversityDomains/GetUniversityDomains?UniversityId=',
      CreateDomain: 'UniversityDomains/CreateDomain',
      UpdateDomain: 'UniversityDomains/UpdateDomain'
    },
    University: {
      University: 'University',
      AddSignifier: 'University/SubmitSignifier',
      UpdateSignifier: 'University/UpdateSignifier',
      GetSignifiers: 'University/GetSignifiers',
      DeleteSignifier: 'University/DeleteSignifier',
      TurnOnOffSignifier: 'University/TurnOnOffSignifier',
      SubmitSignifier: 'University/SubmitUserSignifier',
      TurnOnOffUniversityLogo: 'University/TurnOnOffLogo',
      SubmitUniversityLogo: 'University/SubmitUniversityLogo',
      DownloadStudentsCsv: 'University/DownloadStudentsCsv',
      DeleteUniversityLogo: 'University/DeleteUniversityLogo'
    },
    User: {
      GetUserDetail: 'User/GetUserDetail',
      UpdateUserDetail: 'User/UpdateUserDetail'
    },
    Applicant: {
      Applicant: 'Applicant',
      AddApplicant: 'Applicant/Add',
      GetCount: 'Applicant/GetCount'
    },
    Socities: {
      UniSocieties: 'UniSocieties'
    },
    AppDbSettings: {
      AppDbSettings: 'AppDbSettings'
    }
  }
};
