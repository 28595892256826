import { toastr } from 'react-redux-toastr';
import moment from 'moment';
import { messages } from './messages';
import { WebStorage, WebStorageNames, DateFormat_DateTime } from '../utils';

export class Common {
  static getRecordSorted(record) {
    const me = this;
    if (record !== undefined) {
      const sorted = record.sort(function (firstValue, secondValue) {
        return me.compareValues(
          Number(firstValue.timeHr + '.' + me.getFormated(firstValue.timeMin)),
          Number(secondValue.timeHr + '.' + me.getFormated(secondValue.timeMin))
        );
      });
      return sorted;
    }
    return [];
  }
  static getFormated(value) {
    if (value < 10) {
      return '0' + Number(value);
    }
    return value;
  }
  static compareValues(firstValue, secondValue) {
    if (firstValue < secondValue) {
      return -1;
    }
    if (firstValue > secondValue) {
      return 1;
    }
    return 0;
  }
  static getDOBFormated(value) {
    const date = new Date(value);
    let Day = date.getDate();
    if (Day < 10) {
      Day = '0' + Day;
    }
    let Month = date.getMonth() + 1;
    if (Month < 10) {
      Month = '0' + Month;
    }
    const Year = date.getFullYear().toString().substring(2);
    return Day + '/ ' + Month + '/ ' + Year;
  }

  static setDateOfBirth(dob) {
    return moment(dob);
  }

  static getList(listLength) {
    let list = [];
    for (let element = 0; element < listLength; element++) {
      if (element !== 0)
        list.push({
          id: element,
          name: element
        });
    }
    return list;
  }

  static showErrorMessage(error, title = 'Error') {
    let errorMsg = error;
    const { error403, error404, error400 } = messages.validation;
    if (error.message) {
      errorMsg = error.message;
    }
    if (errorMsg === 'Unexpected end of JSON input') {
      errorMsg = error404;
    }
    if (errorMsg === 'Request failed with status code 400') {
      errorMsg = error400;
    }
    if (errorMsg === 'Un-Authorized Access') {
      errorMsg = error403;
      this.clearUserInfo();
    }
    this.isIE9() ? alert(errorMsg) : toastr.error(title, errorMsg);
  }

  static showErrorMessageTextBox(error, title = 'Error') {
    let errorMsg = error;
    if (error.message) {
      errorMsg = error.message;
    }
    toastr.message(title, errorMsg);
  }

  static showSuccessMessage(message, title = 'Success') {
    this.isIE9() ? alert(message) : toastr.success(title, message);
  }

  static removeAllMessages() {
    toastr.removeByType('error');
    toastr.removeByType('success');
    toastr.removeByType('message');
  }

  static logErrors(
    errorMessage,
    url = window.location.href,
    lineNumber = 0,
    column = 0,
    errorObj = null,
    code = 0
  ) {
    if (code === 404 && code === 401) {
      this.clearUserInfo();
    } else {
      AjaxService.post(config.REST_APIs.Exception.logError, {
        ErrorMessage: errorMessage.toString(),
        Url: url,
        LineNumber: lineNumber
      });
    }
  }
  static handleExceptions(Exception) {
    if (Exception.message === 'Network Error') {
      this.isIE9() ? alert(Exception.message) : Common.showErrorMessage(Exception.message);
    } else if (Exception.message === 'Unexpected end of JSON input') {
      this.isIE9() ? alert(Exception.message) : Common.showErrorMessage(Exception.message);
      this.clearUserInfo();
    } else if (Exception.message === 'Request failed with status code 400') {
      this.isIE9() ? alert(Exception.message) : Common.showErrorMessage(Exception.message);
    } else {
      if (Exception.config && Exception.response.status === 404) {
        Common.logErrors(
          Exception.stack,
          Exception.config.url,
          null,
          null,
          null,
          Exception.response.status
        );
      } else if (Exception.response.status === 401) {
        this.isIE9() ? alert(Exception.message) : Common.showErrorMessage(Exception.message);
        this.clearUserInfo();
      }
      if (Exception.request) {
        Common.logErrors(Exception.stack, Exception.config.url);
        if (Exception.request.responseText !== '') {
          const response = JSON.parse(Exception.request.responseText);
          this.isIE9() ? alert(response.Message) : this.showErrorMessage(response.Message);
        } else {
          this.isIE9()
            ? alert(messages.Exception.exceptionMsg)
            : this.showErrorMessage(messages.Exception.exceptionMsg);
        }
      } else {
        this.isIE9() ? alert(Exception.message) : this.showErrorMessage(Exception.message);
        Common.logErrors(Exception.stack);
      }
    }
  }
  static clearUserInfo() {
    WebStorage.setLocalStore(WebStorageNames.UserInfo, null);
    WebStorage.setLocalStore(WebStorageNames.AuthInfo, null);
    window.location.reload(true);
  }

  // eslint-disable-next-line
  static isIE9 = () => {
    var wn = window.navigator,
      userAgent = wn.userAgent.toLowerCase(),
      storedName;
    // ie
    if (userAgent.indexOf('msie', 0) !== -1) {
      // var browserName = "ie";
      storedName = userAgent.match(/msie[ ]\d{1}/).toString();
      var version = storedName.replace(/msie[ ]/, '');

      // var browserOsVersion = browserName + version;
      return Number(version) === 9;
    }
  };
  static isFunction(functionToCheck) {
    return functionToCheck && {}.toString.call(functionToCheck) === '[object Function]';
  }
}

export const countTotal = (name, arr) => arr.reduce((total, amount) => amount[name] + total, 0);

export const checkDateFormat = (data) => (data === '' ? new Date() : new Date(data));

export const formatDate = (date) => {
  const day = date.getDate();
  const month = date.getMonth() + 1;
  const year = date.getFullYear();
  return `${year}-${month}-${day}`;
};

export const getFormatedDate = (dateStr) => {
  if (!dateStr) return '';
  const formattedDate = moment(dateStr).format(DateFormat_DateTime);
  return formattedDate || '';
};

export const getIsoFormatedDate = (dateStr) => {
  return moment(dateStr, DateFormat_DateTime).parseZone().toISOString(true);
};
